import "../styles/home.scss"
import React, { useRef, Suspense, useEffect, useState } from "react"
// import { Helmet } from "react-helmet";
import { Canvas } from "@react-three/fiber"
import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"
// import SEO from "../components/seo"
// import Layout from "../components/layout"
// import Image from "../components/image"
// import ActOne from "../components/Home/ActOne";
// import Blob from "../components/Home/Blob"
// import DigitalFireteam from "../components/Home/DigitalFireteam";
import ActContact from "../components/Home/ActContact"
// import ActTwo from "../components/Home/ActTwo";
import Header from "../components/Header"
import ProductsAndServices from "../components/Home/ProductsAndServices"
import Banner from "../components/Home/Banner"
// import ActThree from "../components/Home/ActThree";
// import ActFour from "../components/Home/ActFour"
// import Curtain from "../components/Curtain";
import Footer from "../components/Footer"
// import PanelMenu from "../components/PanelMenu"
import firebase from "gatsby-plugin-firebase"
import SuperPowers from "../components/Home/SuperPowers"
import {
  softShadows,
  OrbitControls,
  MeshDistortMaterial,
  MeshWobbleMaterial,
  Sphere,
  Box,
  useTexture,
} from "@react-three/drei"
import Media from "react-media"
// import { useAuth } from "../lib/firebase/firebaseCentral";
// import { useSpring } from "react-spring/three";
// import { Blob } from "../components/Home/ActOne"
// import Marquee from "../components/Home/Marquee"

// import Scroll from "../components/locomotiveScroll"
// import "../components/locomotive-scroll.css"

const IndexPage = ({ location }) => {
  const [deviceRatio, setDeviceRatio] = useState(2)

  useEffect(() => {
    // document.documentElement.style.setProperty(
    //   "--scrollbar-width",
    //   window.innerWidth - document.documentElement.clientWidth + "px"
    // )

    if (window) {
      setDeviceRatio(window.devicePixelRatio)
    }
  }, [])

  // useAuth();
  // console.log("firebase*", firebase);
  // console.log("useAuth()*", useAuth());

  // const [menuState, setMenuState] = useState(false)
  // const [isMobile, setIsMobile] = useState(false)

  // useEffect(() => {
  //   if (menuState) {
  //     document.body.style.overflow = "hidden"
  //   } else {
  //     document.body.style.removeProperty("overflow")
  //   }
  // }, [menuState])

  // useEffect(() => {

  //   if (!curtainReveal) {
  //     document.body.style.overflow = "hidden"
  //     document.body.style.position = "fixed"
  //     // document.body.style.removeProperty("overflow")
  //   } else if (curtainReveal) {
  //     document.body.style.removeProperty("overflow")
  //     document.body.style.removeProperty("position")
  //   }
  // }, [curtainReveal])
  // console.log("window", window.devicePixelRatio)
  return (
    <>
      {/* <SEO
        title="Berserkers"
        description="Nimble digital fireteam to deliver on your business with impactful results at every tactical level in strategy, development, growth, and support."
      /> */}
      {/* <Scroll callbacks={location} /> */}
      {/* <Curtain /> */}
      <Header location={location} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Berserkers</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* <Header menuState={menuState} setMenuState={setMenuState} /> */}
      {/* <PanelMenu menuState={menuState} setMenuState={setMenuState} /> */}
      {/* <ActOne /> */}
      {/* <Blob /> */}

      <SuperPowers />
      {/* <DigitalFireteam /> */}
      <ActContact />
      <div className="canvas">
        <Canvas
          // shadowMap
          // colorManagement
          gl={{ antialias: false }}
          pixelRatio={deviceRatio || 2}
          dpr={2}
          // pixelRatio={}
          // camera={{ position: [0, 2, 10], fov: 60 }}
          // camera={{ position: [0, 0, 8], fov: 60 }}
          camera={{ position: [0, 0, 2.25], fov: 60 }}
          // orthographic
        >
          <Suspense fallback={null}>
            <Banner />
          </Suspense>
        </Canvas>
      </div>
      {/* <ActTwo /> */}
      <ProductsAndServices />
      {/* <ActThree /> */}
      {/* <ActFour /> */}
      <Footer />
      {/* <Marquee /> */}
    </>
  )
}

export default IndexPage
