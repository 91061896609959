import React, { useRef, useState } from "react"
// import { useContext } from "react"
import { Canvas } from "@react-three/fiber"
import Media from "react-media"
// import { useSpring } from "react-spring/three"
import gsap from "gsap"

import { Suspense } from "react"
// import { Blob } from "./ActTwo"
import {
  softShadows,
  OrbitControls,
  MeshDistortMaterial,
  MeshWobbleMaterial,
  Sphere,
  Box,
  useTexture,
} from "@react-three/drei"
// import Curtain from "../Curtain"
// import { SmoothScrollContext } from "../../contexts/SmoothScroll.context";

const ActOne = () => {
  const blobby = React.useRef(null)

  React.useEffect(() => {
    if (blobby.current) {
      gsap.set(blobby.current, { autoAlpha: 1 })

      gsap.from(blobby.current, {
        autoAlpha: 0,
        // scale: 0.25,
        // scale: 0.05,
        // delay: 1.75,
        // delay: 1.25,
        // delay: 2,
        // rotation: 400,
        // rotation: 90,
        // y: -5,
        // y: 2,
        // x: 10,
        duration: 1,
        // x: 40,
        ease: "power3.inOut",
        // stagger: {
        // grid: [7, 15],
        // from: "end",
        // amount: 0.25,
        // amount: 0.15,
        // },
      })
    }
  }, [blobby.current])

  return (
    // <div data-scroll-section className="actone-container">

    <div ref={blobby} className="blobby ">
      <Canvas
        // dpr={2}
        pixelRatio={2}
        gl={{ antialias: false }}
        //   dpr={2}
        colorManagement
        shadowMap
        camera={{ position: [-5, 2, 10], fov: 60 }}
      >
        <Suspense fallback={null}>
          <Blob />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default ActOne

export const Blob = ({ position, color, speed, args, shape }) => {
  // const [small, setSmall] = useState(false)
  const [wobble, setWobble] = useState(false)
  // const texture = useTexture(
  //   // "https://images.unsplash.com/photo-1588007375246-3ee823ef4851?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
  //   // "https://unsplash.com/photos/vAij-E26haI"
  //   // "https://images.unsplash.com/photo-1594185216524-65236d2be4ea?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
  //   // "https://images.unspla
  //   // "https://www.google.com/imgres?imgurl=https%3A%2F%2Fc4.wallpaperflare.com%2Fwallpaper%2F246%2F1000%2F1004%2Fansatsu-kyoushitsu-anime-assassination-classroom-wallpaper-preview.jpg&imgrefurl=https%3A%2F%2Fwww.wallpaperflare.com%2Fansatsu-kyoushitsu-anime-assassination-classroom-yellow-anthropomorphic-smiley-face-wallpaper-hepzi&tbnid=2IUcAS_2uVdTJM&vet=12ahUKEwjN0c3UjeHxAhWJADQIHW3sDYoQMygFegUIARDeAQ..i&docid=jiAqO762w_LYXM&w=728&h=410&itg=1&q=smiley%20face%20anime&ved=2ahUKEwjN0c3UjeHxAhWJADQIHW3sDYoQMygFegUIARDeAQ"
  //   "https://images.unsplash.com/photo-1562447574-797880741dd1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
  // )

  //ref to target the mesh
  const mesh = useRef()

  //useFrame allows us to re-render/update rotation on each frame
  // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01))
  // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.0025))
  // useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.005))

  //Basic expand state
  const [expand, setExpand] = useState(false)
  // React spring expand animation
  // const props = useSpring({
  //   // scale: expand ? [1.4, 1.4, 1.4] : [1, 1, 1],
  //   scale: expand ? [2.5, 2.5, 2.5] : [2.25, 2.25, 2.25],
  // })

  // define mesh/shape with buffer geometry and material.
  return (
    <>
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          laptop: "(min-width: 1200px)",
          desktop: "(min-width: 1600px)",
          largeDesktop: "(min-width: 1920px)",
        }}
      >
        {matches => (
          <>
            {/*An ambient light that creates a soft light against the object */}
            {/* <ambientLight intensity={0.5} /> */}
            <ambientLight intensity={0.5} />
            {/*An directional light which aims form the given position */}
            {/* <directionalLight position={[10, 10, 5]} intensity={5} /> */}
            <directionalLight position={[2, 5, 5]} intensity={1} />
            {/*An point light, basically the same as directional. This one points from under */}
            {/* <pointLight position={[0, -10, 5]} intensity={5} /> */}
            <pointLight position={[0, -5, 5]} intensity={1} />

            {/* This sphere has a distort material attached to it */}
            {/* {!small && ( */}
            <Sphere
              onClick={() => setWobble(w => !w)}
              scale={matches.laptop ? [5.5, 5.5, 5.5] : [5.5, 5.5, 5.5]}
              visible
              position={[0, 0, 0]}
              args={matches.laptop ? [0.8, 200, 300] : [0.8, 200, 300]}
            >
              <MeshDistortMaterial
                // color="#181818"
                // color="#A3293A"
                // color="#058D9D"
                // color="#035761"
                // color="#272727"
                color="#1860cc"
                // color="#7D0C1C"
                // color="#7D0C1C"
                attach="material"
                distort={0.5} // Strength, 0 disables the effect (default=1)
                speed={wobble ? 10 : 1} // Speed (default=1)
                roughness={0}
              />
            </Sphere>
          </>
        )}
      </Media>

      {/* )} */}
    </>
  )
}
